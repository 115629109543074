import { computed } from 'vue'
import { useRoute } from 'vue-router'

/**
 * определяет корневой раздел у страниц
 */
export function useMatchLinkToRoute() {
  const route = useRoute()

  function checkMatchLinkToRoute(link: string) {
    const reg = new RegExp(link)
    return route?.path.match(reg)
  }

  const isSportPage = computed(() => checkMatchLinkToRoute('/sport'))
  const isCyberSportPage = computed(() => checkMatchLinkToRoute('/cybersport'))
  const isCasinoPage = computed(() => checkMatchLinkToRoute('/casino'))

  const currentPage = computed<'sport' | 'cybersport' | 'casino' | 'static'>(
    () => {
      if (isSportPage.value) return 'sport'
      if (isCyberSportPage.value) return 'cybersport'
      if (isCasinoPage.value) return 'casino'

      return 'static'
    },
  )
  return {
    checkMatchLinkToRoute,
    isSportPage,
    isCyberSportPage,
    isCasinoPage,
    currentPage,
  }
}
